import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import { ClosePageProvider } from './contexts/ClosePageContext';
import InstantInsightLayout from './components/instant-insight/InstantInsightLayout';
import InstantInsightAuthGuard from './components/instant-insight/InstantInsightAuthGuard';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const RegisterSMM = Loadable(lazy(() => import('./pages/authentication/RegisterSMM')));


// Blog pages

const BlogPostCreateOrUpdate = Loadable(lazy(() => import('./pages/blog/BlogPostCreateOrUpdate')));
const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));

// Dashboard pages

const Homepage = Loadable(lazy(() => import('./pages/dashboard/Homepage')));
const Reviews = Loadable(lazy(() => import('@src/ui/pages/reviews/reviews.container')));
const Settings = Loadable(lazy(() => import('./pages/dashboard/Settings')));
const StripeConnectSetup = Loadable(lazy(() => import('./pages/dashboard/StripeConnectSetup')));
const DnsConfiguration = Loadable(lazy(() => import('./pages/dashboard/DnsConfiguration')));
const ImportCheckfront = Loadable(lazy(() => import('./pages/import/checkfront/Import')));
const ImportPeek = Loadable(lazy(() => import('./pages/import/peek/Import')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));

const Tours = Loadable(lazy(() => import('./pages/tour/Tours')));
const TourEditOrCreate = Loadable(lazy(() => import('./pages/tour/TourEditOrCreate')));
const CategoryEditOrCreate = Loadable(lazy(() => import('./pages/categories/CategoryCreateOrUpdate')));
const GeneralInfo = Loadable(lazy(() => import('./pages/general-info/GeneralInfo')));
const Review = Loadable(lazy(() => import('./pages/review/Review')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));

const Leads = Loadable(lazy(() => import('@src/ui/pages/leads/leads.container')));
const SocialMediaManagement = Loadable(lazy(() => import('@src/ui/pages/social-media-management/social-media-management.container')));
const Marketplace = Loadable(lazy(() => import('@src/ui/pages/marketplace/marketplace.container')));
const MarketplaceItem = Loadable(lazy(() => import('@src/ui/pages/marketplace/marketplace-item/marketplace-item.container')));

const GPT3 = Loadable(lazy(() => import('@src/ui/pages/gpt3/gpt3.container')));

// Booking
const BookingFlowContainer = Loadable(lazy(() => import('@src/ui/pages/booking/booking-flow.container')));

// InstantInsights
const InstantInsightsAuthContainer = Loadable(lazy(() => import('@src/ui/pages/instant-insights/instant-insights.auth.container')));
const InstantInsightsContainer = Loadable(lazy(() => import('@src/ui/pages/instant-insights/instant-insights.container')));

// Site pages
const SitePageCreateOrUpdate = Loadable(lazy(() => import('./pages/site-pages/SitePageCreateOrUpdate')));
const SitePageList = Loadable(lazy(() => import('./pages/site-pages/SitePageList')));

const PremiumInviteContainer = Loadable(lazy(() => import('@src/ui/pages/premium-invite/premium-invite.container')));
const Redirects = Loadable(lazy(() => import('./pages/dashboard/Redirects')));


const routes = [
  {
    path: '/',
    element: <Navigate to="/dashboard" />
  },
  {
    path: '/authentication',
    children: [
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'register-smm',
        element: <RegisterSMM />
      },
      {
        path: 'instant-insights',
        children: [
            {
              path: 'google/connect',
              element: <InstantInsightsAuthContainer step={'connect'} />
            },
            {
              path: 'google/callback',
              element: <InstantInsightsAuthContainer step={'callback'} />
            },
        ]
      },
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/dashboard/overview" />
      },
      {
        path: 'overview',
        children: [
          {
            path: '',
            element: <Homepage />
          },
          {
            path: 'review',
            element: <Review />
          },
          {
            path: 'tours',
            children: [
              {
                path: '',
                element: <Tours defaultTab="tours"/>
              },
              {
                path: 'create',
                element: <ClosePageProvider><TourEditOrCreate reason="create" /></ClosePageProvider>
              },
              {
                path: 'edit/:slug',
                element: <ClosePageProvider><TourEditOrCreate reason="edit" /></ClosePageProvider>
              },
              {
                path: 'import/checkfront',
                element: <ImportCheckfront />
              },
              {
                path: 'import/peek',
                element: <ImportPeek />
              },
            ]
          },
          {
            path: 'categories',
            children: [
              {
                path: '',
                element: <Tours defaultTab="categories"/>
              },
              {
                path: 'create',
                element: <ClosePageProvider><CategoryEditOrCreate reason="create" /></ClosePageProvider>
              },
              {
                path: 'edit/:slug',
                element: <ClosePageProvider><CategoryEditOrCreate reason="edit" /></ClosePageProvider>
              }
            ]
          },
          {
            path: 'general-info',
            element: <ClosePageProvider><GeneralInfo /></ClosePageProvider>
          },
        ]
      },
      {
        path: 'analytics',
        element: <Analytics />
      },
      {
        path: 'reviews',
        children: [
          {
            path: '',
            element: <Reviews currentTab={'dashboard'} />
          },
          {
            path: 'dashboard',
            element: <Reviews currentTab={'dashboard'} />
          },
          {
            path: 'customize',
            element: <Reviews currentTab={'customize'}/>
          },
          {
            path: 'connect',
            element: <Reviews currentTab={'connect'}/>
          },
        ],
      },
      {
        path: 'settings',
        children: [
          {
            path: '',
            element: <Navigate to="/dashboard/settings/account" />
          },
          {
            path: 'account',
            element: <Settings />,
          },
          {
            path: 'email-notifications',
            element: <Settings />,
          },
          {
            path: 'stripe-connect-setup',
            element: <StripeConnectSetup />
          },
        ],
      },
      {
        path: 'blog',
        children: [
          {
            path: '',
            element: <BlogPostList />,
          },
          {
            path: 'create',
            element: <ClosePageProvider><BlogPostCreateOrUpdate reason="create" /></ClosePageProvider>
          },
          {
            path: 'edit/:slug',
            element: <ClosePageProvider><BlogPostCreateOrUpdate reason="edit" /></ClosePageProvider>
          },
        ],
      },
      {
        path: 'dns-configuration',
        element: <DnsConfiguration />
      },
      {
        path: 'leads',
        children: [
          {
            path: '',
            element: <Leads />
          },
          {
            path: '/:leadId',
            element: <Leads />
          },
        ]
      },
      {
        path: 'social-media-management',
        children: [
          {
            path: '',
            element: <SocialMediaManagement currentTab="ideas" />
          },
          {
            path: '/ideas',
            element: <SocialMediaManagement currentTab="ideas"  />
          },
          {
            path: '/templates',
            element: <SocialMediaManagement currentTab="templates"  />
          },
          {
            path: '/image-captioner',
            element: <SocialMediaManagement currentTab="image-captioner"  />
          },
          {
            path: '/preferences',
            element: <SocialMediaManagement currentTab="preferences"  />
          },

        ]
      },
      {
        path: 'marketplace',
        children: [
          {
            path: '',
            element: <Marketplace />
          },
          {
            path: '/:slug',
            element: <ClosePageProvider><MarketplaceItem /></ClosePageProvider>
          }
        ]
      },
      {
        path: 'GPT3',
        element: <GPT3 />
      },
      {
        path: 'premium-invite',
        element: <PremiumInviteContainer/>
      },
      {
        path: 'redirects',
        element: <Redirects />
      },
      {
        path: 'pages',
        children: [
          {
            path: '',
            element: <SitePageList />,
          },
          {
            path: 'create',
            element: <ClosePageProvider><SitePageCreateOrUpdate reason="create" /></ClosePageProvider>
          },
          {
            path: 'edit/:slug',
            element: <ClosePageProvider><SitePageCreateOrUpdate reason="edit" /></ClosePageProvider>
          },
        ],
      },
    ]
  },
  {
    path: 'widget',
    children: [
      {
        path: '/:company_id/tours/:tour_id',
        element: <BookingFlowContainer step={'date-select'} />
      },
      {
        path: '/:company_id/tours/:tour_id/book/:availability_id',
        element: <BookingFlowContainer step={'checkout'}/>
      },
      {
        path: '/:company_id/tours/:tour_id/book/:availability_id/result',
        element: <BookingFlowContainer step={'result'}/>
      },
    ]
  },
  {
    path: 'instant-insights',
    element:
      <InstantInsightAuthGuard>
        <InstantInsightLayout />
      </InstantInsightAuthGuard>,
    children: [
      {
        path: '',
        element: <Navigate to="/instant-insights/home" />
      },

      {
        path: '/home',
        element: <InstantInsightsContainer/>
      },
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
